.s1-report-container table,
.s1-report-container tr,
.s1-report-container .s1-nested-container {
  background-color: var(--bs-body-bg) !important;
}

.clientinfo + .header {
  border-top: 15px solid var(--bs-body-bg) !important;
}

.s1-report-container .outterhheader td,
.s1-report-container .header td {
  background-color: var(--bs-primary) !important;
}

.s1-report-container tr:not(.header):not(.footer):hover > td:not(.s1-nested-container):not([colspan]),
.s1-report-container tr.hilighted > td {
  background-color: var(--bs-row-selected) !important;
}

.s1-report-container .s1-nested-container:before {
  border-color: var(--bs-primary) transparent transparent var(--bs-primary) !important;
}

.s1-report-container .footer td {
  border-top: 2px solid var(--bs-primary) !important;
  /* background: var(--bs-secondary) !important; */
}
