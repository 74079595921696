#root {
  height: 100dvh;
  /* background-color: var(--main-color); */
}
body,
html,
#root {
  overflow: hidden;
  overscroll-behavior: none;
}

legend {
  user-select: none;
}

.bs-theme-color {
  content: var(--bs-body-bg);
}

:root {
  --bs-primary: #1274bb;
  --bs-color-opposite: var(--bs-light);
  --bs-category-5: #13b994;
  /* --bs-secondary: #28242c; */
  --bs-primary-rgb: 18, 116, 187;
  /* --main-color: rgb(248, 249, 250); */
  --object-bar-background: #1274bb;
  --side-bar-background: #1274bb;
  --fc-highlight-color: rgba(10, 88, 202, 0.3);
  --bs-secondary-row: #f2f2f2;
  --bs-row-selected: #dee3ec;
  --bs-web-menu-border-color-rgba: 0, 0, 0, 0.15;
  --custom-border-color: #c0c9d0;
  --fc-button-active-bg-color: #565e64;
  --fc-button-hover-bg-color: #5c636a;
  --fc-button-bg-color: #6c757d;
  --fc-button-border-color: #6c757d;
  --color-text: #dee2e6;
  --card-bg: var(--bs-body-bg);
  --showroom-bg: #efefef;
}

[data-bs-theme="dark"] {
  --bs-secondary-row: #2c3035;
  --bs-color-opposite: var(--bs-dark);
  --bs-row-selected: #515f69;
  --fc-highlight-color: rgba(10, 88, 202, 0.3);
  --custom-border-color: var(--bs-border-color);
  --card-bg: var(--bs-body-bg);
  --showroom-bg: #373843;
}
.fc-button {
  box-shadow: none !important;
  border: none !important;
}
/* .btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #28242c;
  --bs-btn-border-color: #404454;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #404454;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #404454;
  --bs-btn-disabled-border-color: #404454;
} */

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0a58ca;
  --bs-btn-border-color: #0a58ca;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #145c9c;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1274bb;
  --bs-btn-disabled-border-color: #1274bb;
}

.btn-outline-secondary {
  --bs-btn-color: var(--bs-body-color);
}

.object-top-bar button.btn-secondary,
.s1reldocs-bar button.btn-secondary,
.s1detailgrid-bar button.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1274bb;
  --bs-btn-border-color: #1274bb;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1274bb;
  --bs-btn-hover-border-color: #1274bb;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #105a94;
  --bs-btn-active-border-color: #1274bb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1274bb;
  --bs-btn-disabled-border-color: #1274bb;
}

.gadget-top-bar.category-5 button.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #13b994;
  --bs-btn-border-color: #13b994;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #079477;
  --bs-btn-hover-border-color: #079477;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #079477;
  --bs-btn-active-border-color: #079477;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #a8f2d8;
  --bs-btn-disabled-border-color: #a8f2d8;
}

.s1-showroom-card-actions button.btn-secondary {
  --bs-btn-color: var(--bs-primary);
  --bs-btn-bg: var(--card-bg);
  --bs-btn-border-color: var(--card-bg);
  --bs-btn-hover-color: var(--bs-primary);
  --bs-btn-hover-bg: var(--showroom-bg);
  --bs-btn-hover-border-color: var(--showroom-bg);
  --bs-btn-focus-shadow-rgb: 360, 100, 94;
  --bs-btn-active-color: var(--bs-primary);
  --bs-btn-active-bg: var(--card-bg);
  --bs-btn-active-border-color: var(--card-bg);
  --bs-btn-active-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-primary);
  --bs-btn-disabled-bg: var(--showroom-bg);
  --bs-btn-disabled-border-color: var(--showroom-bg);
}

.s1-showroom-card-actions .btn:disabled {
  background-color: var(--card-bg);
  border-color: var(--card-bg);
  opacity: 0.5;
}

.s1-showroom-card-actions .btn:focus-visible {
  box-shadow: none;
}

.object-top-bar input.search::placeholder {
  opacity: 0.5;
  color: white;
}

.dropdown-menu {
  --bs-dropdown-link-active-bg: #0a58ca;
}

[data-attributes="dark"] {
  --main-color: rgb(33, 37, 41);
  --object-bar-background: #38b4b4;
  --side-bar-background: #38b4b4;
}

select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
}

.hidden {
  display: none;
}

.transparent {
  background: transparent;
}

.crm-floating-with-icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  border-bottom: 1px solid var(--bs-gray);
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
}

.form-floating > label {
  padding: 1rem 0 !important;
  background-color: transparent !important;
}

.form-floating.can-redirect > label {
  color: var(--bs-primary) !important;
}

.form-floating > label::after {
  background-color: transparent !important;
}

input,
textarea {
  background: transparent;
}

input[type="checkbox"]:checked {
  background-color: var(--bs-primary);
}

.form-control:disabled {
  background-color: transparent !important;
  opacity: 0.7 !important;
}

.form-control[readonly] {
  background-color: transparent !important;
  opacity: 1;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: none !important;
  background-color: transparent !important;
}

.form-control {
  background-color: transparent !important;
}

.portal-background {
  background-image: url("/src/assets/portal.svg");
  background-position: center;
  background-size: cover;
}

.sideBar {
  /* background-color: var(--bs-gray-dark);
  user-select: none;
  /* position: relative; */
  overflow: hidden;
  display: flex;
  max-width: 80%;
  /* top: 0px;
  left: 0px; */
  /* padding-top: 56px; */
  height: 100%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  z-index: 2001;

  /* --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; */
}

.sideBar-backdrop {
  z-index: 2001;
}

.groupBar {
  /* background-color: var(--bs-gray-dark); */
  user-select: none;
  overflow: hidden;
  position: relative;
  display: flex;
  /* top: 0px;
  left: 0px; */
  /* padding-top: 56px; */
  /* height: 100%; */
  /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; */
}

.groupBar-handle {
  position: absolute;
  z-index: 100;
  width: 4px;
  top: 0;
  bottom: 0;
  right: 0;
  transition: 0.3s;
  background: var(--bs-gray-600);
}

.sideBar-handle {
  position: absolute;
  z-index: 100;
  width: 4px;
  top: 0;
  bottom: 0;
  right: 0;
  transition: 0.3s;
  background: var(--bs-gray-600);
}

.groupBar-handle-closed {
  position: absolute;
  z-index: 100;
  width: 4px;
  top: 0;
  bottom: 0;
  right: 0;
  transition: 0.3s;
  background: var(--bs-gray-600);
}

.sideBar-handle-closed {
  position: absolute;
  z-index: 100;
  width: 4px;
  top: 0;
  bottom: 0;
  right: 0;
  transition: 0.3s;
  background: var(--bs-gray-600);
}

.groupBar-handle:hover,
.groupBar-handle:active {
  background: var(--bs-gray-500);
  cursor: col-resize;
}

.group-fields {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.group-by-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  padding: 0 1rem 0 1rem;
  height: 3rem;
  min-height: 3rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 1rem;
}
.group-by-item.selected {
  background-color: var(--bs-gray-300) !important;
  border-bottom: 1px solid var(--bs-primary);
}

.group-by-item:hover {
  background-color: var(--bs-gray-400) !important;
}

.sideBar-handle:hover,
.sideBar-handle:active {
  background: var(--bs-gray-500);
  cursor: col-resize;
}

.gadget-handle {
  position: absolute;
  z-index: 100;
  height: 4px;
  width: 100%;
  bottom: 0;
  right: 0;
  transition: 0.3s;
  background: var(--bs-gray-600);
}

.gadget-handle:hover,
.gadget-handle:active {
  background: var(--bs-gray-500);
  cursor: row-resize;
}

.s1-web-menu {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  max-height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
}

.s1-web-menu:first-child {
  border-top: 2px solid rgba(var(--bs-web-menu-border-color-rgba));
}

.s1-web-menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  padding-left: 1rem;
  height: 3rem;
  min-height: 3rem;
  border: 1px solid rgba(var(--bs-web-menu-border-color-rgba));
  font-size: 1.2rem;
}
.s1-web-menu-item > div > svg {
  font-size: 1rem;
}

.s1-web-menu-item.current-folder {
  background-color: var(--bs-secondary);
  color: var(--color-text);
  /* padding: 1rem 1rem; */
  height: 3rem;
}

.s1-browser-menu-title {
  margin: 0 1rem 0 1rem;
  border-bottom: 1px solid var(--bs-dropdown-link-color);
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  text-wrap: nowrap;
}

.s1-browser-menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8rem;
  padding-left: 1rem;
  height: 2rem;
  font-size: 1.2rem;
  min-height: 2rem;
}

.s1-rel-job-menu-title {
  margin: 0 1rem 0 1rem;
  border-bottom: 1px solid var(--bs-dropdown-link-color);
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  text-wrap: nowrap;
}

.s1-rel-job-menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8rem;
  padding-left: 1rem;
  height: 2rem;
  min-height: 2rem;
}

.task-drop-down-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding-left: 1rem;
  margin: 0;
  height: 2.5rem;
  min-height: 2.5rem;
}

.task-drop-down-item.task-active {
  background-color: var(--bs-row-selected);
  border-bottom: 1px solid var(--bs-primary);
}

.tab-list {
  display: flex;
  width: 100%;
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity));
  height: 36px;
  background-color: var(--bs-gray-600);
  position: relative;
  overflow-x: hidden;
}

.tab-list-wrapper {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}

.tab-list-controls {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
}

.tab-list-control-left,
.tab-list-control-right {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: var(--bs-light);
  color: var(--bs-dark);
}

.tab-list-control-left {
  margin: 0.25rem 0.25rem 0 0;
}

.tab-list-control-right {
  margin: 0.25rem 0.25rem 0 0.25rem;
}

.tab-list-context-menu {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.175);
  /* background-color: var(--bs-body-color); */
  display: flex;
  flex-direction: column;
  padding: 10px 0 10px 0;
  position: fixed;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.3);
  z-index: 2001;
}

.tab-list-context-menu-action {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  margin: 0.15rem 0 0.1rem 0;
  padding: 0.25rem 1rem;
  user-select: none;
}

.tab-list-context-menu-action.active {
  /* background-color: #e9ecef; */
  border: 0;
}

.tab-list-context-menu-action:hover {
  /* background-color: #e9ecef; */
}

.tab-window {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 0.25rem; */
  margin-top: 0.25rem;
  cursor: pointer;
  background-color: var(--bs-gray-dark);
  color: var(--bs-gray-light);
  user-select: none;
  max-width: 300px;
}

/* .tab-window.dashboard{
  background-color: var(--bs-gray-600);
  color: var(--bs-gray-light);
} */

.tab-window.active {
  background-color: var(--bs-light);
  color: var(--bs-dark);
}

.tab-panel {
  display: none;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.tab-panel.dashboard {
  background-image: url("/src/assets/portal.svg");
  background-position: center;
  background-size: cover;
}

.tab-panel.active {
  display: flex;
}

.gadget-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  --bs-text-opacity: 1;
  padding: 0.5rem 0.75rem;
  color: var(--bs-dark);
  background-color: var(--bs-gray-400);
}

.gadget-top-bar.category-5 {
  color: var(--bs-light);
  background-color: #13b994;
}

.object-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity));
  /* overflow: hidden; */
  /* margin-top: 0.5rem; */
  /* height: calc(3rem - 8px); */
  background-color: var(--bs-primary);
  padding: 0.5rem 0.75rem;
}

.object-top-bar.form {
  justify-content: space-between;
  gap: 0;
}

.inputGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0.4rem 0;
}

.inputGroup.has-error {
  border-bottom: 1px solid red;
}

.inputGroup label {
  /* width: 120px; */
  /* min-width: 120px;
  max-width: 120px; */
  /* margin-right: 5px; */
  /* height: 30px;
  display: flex;
  /* flex-wrap: nowrap; */
  /*align-items: center; */
  border-bottom: 1px dotted #b9b9b9;
  height: 30px;
  min-width: 140px;
  max-width: 140px;
}

.input-with-buttons {
  margin-left: 0.25rem;
  display: flex;
  width: 100%;
  /* gap: 0.5rem; */
  border-bottom: 1px solid #b9b9b9;
  height: 30px;
}

.text-area-input-group {
  display: flex;
  flex-direction: column;
  margin: 0.4rem 0;
}

.text-area-input-group label {
  height: 30px;
  border-bottom: 1px dotted #b9b9b9;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.text-area-input-group textarea {
  width: 100%;
  border: none;
  outline: none;
}

.textarea-with-buttons {
  display: flex;
  width: 100%;
  gap: 0.5rem;
  border: 1px solid #b9b9b9;
}

.underline-focus {
  border-bottom: 1px solid var(--bs-primary);
}

.whole-focus {
  border: 1px solid var(--bs-primary);
}

.whole-required {
  border: 1px solid var(--bs-danger) !important;
}

.inputGroup input {
  width: 100%;
  border: none;
  outline: none;
}

.input-required {
  border-bottom: 1px solid var(--bs-danger) !important;
}

div.required {
  border-bottom: 2px solid var(--bs-danger) !important;
}

/* input {
  background-color: var(--bs-light);
} */

input.disabled {
  opacity: 0.7;
  cursor: default;
}

svg.disabled {
  opacity: 0.5 !important;
  cursor: default;
}

textarea {
  background-color: var(--bs-light);
}

.loadModal {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2000;
}

.picker {
  z-index: 100000 !important;
}

.picker-search {
  padding: 0.25rem 0.5rem;
  border-bottom: 1px solid var(--custom-border-color);
  gap: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.picker-search input {
  width: 100%;
  border: none;
  outline: none;
}

.input-picker {
  background-color: var(--bs-body-bg);
  padding: 0;
  /* border: 1px solid var(--bs-secondary); */
}

.picker-buttons {
  padding: 0;
  width: 100%;
}

.picker-buttons > div {
  margin: 0;
  width: 100%;
}

.picker-buttons > div > button {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0;
}

.picker-buttons > div :first-child {
  border-left: 0;
}

.picker-buttons > div :nth-child(2) {
  border-right: 0;
}

.input-picker-load {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 2000;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  background-color: var(--bs-backdrop-bg);
  opacity: var(--bs-backdrop-opacity);
}

.tab-loader {
  height: 1rem;
  width: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-loader > div {
  width: 4px;
  height: 4px;
  margin: 1px;
  border-radius: 50%;
  background-color: var(--bs-dark);
  opacity: 1;
  animation: tab-loader 0.6s infinite alternate;
}

@keyframes tab-loader {
  to {
    opacity: 0.1;
    transform: translateY(-2px);
  }
}

.tab-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.tab-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.screen-loader {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2000;
}

.bouncing-loader {
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bouncing-loader > div {
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: var(--bs-primary);
  border: 0.5px solid var(--bs-body-color);
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.nav-tabs {
  border-bottom: none;
}

.nav-link {
  border: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 8px 16px;
  margin-right: -1px;
  background-color: #f1f1f1;
  color: #444;
}

.nav-link.active {
  background-color: #fff;
  color: #000;
  border: none;
  border-bottom-color: #fff;
  z-index: 1;
}

.tab-pane {
  padding: 16px;
  border: 1px solid #ddd;
  border-top: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #fff;
}

.s1-object-dialog {
  padding: 0 0.5rem 0.5rem 0.5rem;
  height: 100%;
  overflow-y: auto;
}

.s1-object-browser {
  overflow-y: hidden;
  height: 100%;
}

.s1-object-showroom {
  background-color: var(--showroom-bg);
  overflow-x: hidden;
  height: 100%;
  padding: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  /* justify-content: space-between; */
}

.s1-object-form {
  padding: 0 0.5rem 0.5rem 0.5rem;
  height: 100%;
  overflow: hidden auto;
  scrollbar-width: thin;
}

.s1-portal,
.s1-settings,
.s1-userinfo {
  padding: 0.5rem;
  height: 100%;
  overflow: auto;
  scrollbar-width: thin;
}

.s1-settings,
.s1-userinfo {
  background-color: var(--bs-body-bg);
}

.s1cont {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;

  @media only screen and (min-width: 640px) {
    gap: 0.5rem;
    flex-direction: row;
  }
}

.s1cont.daterange {
  flex-direction: row;
  border-bottom: 1px solid var(--bs-gray);
}

.input-picker-div {
  position: fixed;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.3);
  border: 1px solid var(--bs-secondary);
  background-color: var(--bs-body-bg);
  display: flex;
  flex-direction: column;
  z-index: 100;
}

.input-picker-table:first-child {
  border-top: 2px solid var(--custom-border-color);
}

.input-picker-table-row {
  user-select: none;
  cursor: pointer;
  border-width: 1px;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid var(--custom-border-color);
  padding: 1rem 1rem;
  /* min-height: 55px; */
}

.input-picker-div.custom .input-picker-table-row {
  padding: 0 1rem;
}

.input-picker-div.custom .input-picker-table-row > span {
  padding: 0.5rem 0;
}

.input-picker-table-row > span {
  display: inline-block;
  margin-right: 1rem;
}

.input-picker-table-row-selected {
  background-color: var(--bs-row-selected);
  border-bottom: 2px solid var(--bs-primary);
}

.input-picker-table-row:nth-child(even) {
  background-color: var(--bs-secondary-row);
}

.input-picker-table-row:nth-child(even).input-picker-table-row-selected {
  background-color: var(--bs-row-selected);
  border-bottom: 2px solid var(--bs-primary);
}

.browser-list {
  overflow-y: auto;
  height: 100%;
  width: 100%;
}

.browser-list:first-child {
  border-top: 1px solid var(--bs-gray-700);
}

.browser-list-row {
  padding: 0.25rem 0.5rem;
  border-bottom: 1px solid var(--bs-gray-700);
  overflow-x: hidden;
}

.browser-list-row.with-image {
  min-height: 132;
}

.browser-list-row .image-div {
  border: 1px dotted rgb(65, 70, 75);
  float: left;
  margin-right: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.browser-list-row.with-image .browser-list-row-detail {
  display: contents;
}

.browser-list-row.with-image .browser-list-row-detail > span:nth-child(2) {
  margin-right: 0.5rem;
}

.image-div .image-on-list {
  background-position: 50% 50%;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100px;
  width: 100px;
}

.image-div .no-image-on-list {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
}

.browser-list-row.selected {
  background-color: var(--bs-row-selected);
  border-bottom: 1px solid var(--bs-primary);
}

.browser-list-row-header {
  font-size: 20px;
  margin-bottom: 0.25rem;
}

.browser-list-row-header span:first-child {
  color: rgba(var(--bs-body-color-rgb), 0.8);
  font-weight: 600;
  margin-right: 0.5rem;
}

.browser-list-row-header span:nth-child(2) {
  font-weight: 500;
}

.browser-list-row-detail {
  display: inline-block;
  margin-right: 1rem;
}

.browser-list-row-detail span:first-child {
  color: rgba(var(--bs-body-color-rgb), 0.8);
}

.browser-list-row-detail span:nth-child(2) {
  font-weight: 500;
}

.browser-table-thead {
  background-color: var(--bs-secondary);
}

.browser-table-tbody {
  scrollbar-width: thin;
}

.browser-table-tfoot {
  background-color: var(--bs-secondary);
  overflow-y: auto;
  overflow-x: hidden;
}

th {
  font-weight: normal;
}

.browser-table-resizer {
  display: inline-block;
  background: var(--bs-gray-600);
  width: 12px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  touch-action: none;
}

.browser-table-resizer:hover {
  background: var(--bs-gray-500);
}

.browser-table-resizer.isResizing {
  background: var(--bs-gray-500);
}

.browser-table-thead > .browser-table-tr > .browser-table-th {
  background-color: var(--bs-secondary);
  color: var(--bs-light);
  padding: 0.5rem;
  user-select: none;
  padding: 0.5rem;
  position: relative;
}

.browser-table-tfoot > .browser-table-tr > .browser-table-td {
  background-color: var(--bs-secondary);
  color: var(--bs-light);
  user-select: none;
  padding: 0.5rem;
  position: relative;
}

.browser-table-tbody > .browser-table-tr:nth-child(even) > div {
  background-color: var(--bs-secondary-row);
}

.table > :not(caption) > * > * {
  background-color: transparent;
}

.browser-table-tbody > .browser-table-tr.row-selected .browser-table-td {
  background-color: var(--bs-row-selected);
  border-bottom: 1px solid var(--bs-primary);
}

.browser-table-tbody > .browser-table-tr {
  user-select: none;
}

.browser-table-tbody > .browser-table-tr > .browser-table-td {
  padding: 0.5rem;
  position: relative;
}

input[type="date"]::-webkit-datetime-edit-text,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-day-field,
input[type="date"]::-webkit-datetime-edit-year-field {
  -webkit-appearance: textfield;
  opacity: 0%;
}

input[type="date"].date-input-has-value::-webkit-datetime-edit-text,
input[type="date"].date-input-has-value::-webkit-datetime-edit-month-field,
input[type="date"].date-input-has-value::-webkit-datetime-edit-day-field,
input[type="date"].date-input-has-value::-webkit-datetime-edit-year-field {
  opacity: 100%;
}

input[type="datetime-local"]::-webkit-datetime-edit-text,
input[type="datetime-local"]::-webkit-datetime-edit-month-field,
input[type="datetime-local"]::-webkit-datetime-edit-day-field,
input[type="datetime-local"]::-webkit-datetime-edit-hour-field,
input[type="datetime-local"]::-webkit-datetime-edit-minute-field,
input[type="datetime-local"]::-webkit-datetime-edit-ampm-field,
input[type="datetime-local"]::-webkit-datetime-edit-day-field,
input[type="datetime-local"]::-webkit-datetime-edit-year-field {
  opacity: 0%;
}

input[type="datetime-local"].date-input-has-value::-webkit-datetime-edit-text,
input[type="datetime-local"].date-input-has-value::-webkit-datetime-edit-month-field,
input[type="datetime-local"].date-input-has-value::-webkit-datetime-edit-day-field,
input[type="datetime-local"].date-input-has-value::-webkit-datetime-edit-hour-field,
input[type="datetime-local"].date-input-has-value::-webkit-datetime-edit-minute-field,
input[type="datetime-local"].date-input-has-value::-webkit-datetime-edit-ampm-field,
input[type="datetime-local"].date-input-has-value::-webkit-datetime-edit-day-field,
input[type="datetime-local"].date-input-has-value::-webkit-datetime-edit-year-field {
  opacity: 100%;
}

input[type="time"]::-webkit-datetime-edit-text,
input[type="time"]::-webkit-datetime-edit-hour-field,
input[type="time"]::-webkit-datetime-edit-minute-field,
input[type="time"]::-webkit-datetime-edit-ampm-field {
  opacity: 0%;
}

input[type="time"].date-input-has-value::-webkit-datetime-edit-text,
input[type="time"].date-input-has-value::-webkit-datetime-edit-hour-field,
input[type="time"].date-input-has-value::-webkit-datetime-edit-minute-field,
input[type="time"].date-input-has-value::-webkit-datetime-edit-ampm-field {
  opacity: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.preview-open {
  width: 100vw;
  max-width: none;
  height: 100%;
  padding: 0.5rem;
  margin: 0;
}

.preview-open .modal-content {
  height: 100%;
}

.btn-close:focus {
  box-shadow: none !important;
}

.no-preview {
  cursor: row-resize;
  background-color: transparent;
}

.s1-web-menu::-webkit-scrollbar,
.group-fields::-webkit-scrollbar,
.s1-object-dialog::-webkit-scrollbar,
.softone-report::-webkit-scrollbar,
.input-picker::-webkit-scrollbar,
.input-picker-div::-webkit-scrollbar,
.s1-portal::-webkit-scrollbar,
.s1-object-form::-webkit-scrollbar,
.browser-table-tbody::-webkit-scrollbar,
.fc-scroller::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
}

.s1-web-menu::-webkit-scrollbar-thumb,
.group-fields::-webkit-scrollbar-thumb,
.s1-object-dialog::-webkit-scrollbar-thumb,
.softone-report::-webkit-scrollbar-thumb,
.input-picker::-webkit-scrollbar-thumb,
.input-picker-div::-webkit-scrollbar-thumb,
.s1-portal::-webkit-scrollbar-thumb,
.s1-object-form::-webkit-scrollbar-thumb,
.browser-table-tbody::-webkit-scrollbar-thumb,
.fc-scroller::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the scrollbar thumb */
}

.s1-web-menu::-webkit-scrollbar-thumb:hover,
.group-fields::-webkit-scrollbar-thumb:hover,
.s1-object-dialog::-webkit-scrollbar-thumb:hover,
.softone-report::-webkit-scrollbar-thumb:hover,
.input-picker::-webkit-scrollbar-thumb:hover,
.input-picker-div::-webkit-scrollbar-thumb:hover,
.s1-portal::-webkit-scrollbar-thumb:hover,
.s1-object-form::-webkit-scrollbar-thumb:hover,
.browser-table-tbody::-webkit-scrollbar-thumb:hover,
.fc-scroller::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the scrollbar thumb on hover */
}

.s1-web-menu::-webkit-scrollbar-track,
.group-fields::-webkit-scrollbar-track,
.s1-object-dialog::-webkit-scrollbar-track,
.softone-report::-webkit-scrollbar-track,
.input-picker::-webkit-scrollbar-track,
.input-picker-div::-webkit-scrollbar-track,
.s1-portal::-webkit-scrollbar-track,
.s1-object-form::-webkit-scrollbar-track,
.browser-table-tbody::-webkit-scrollbar-track,
.fc-scroller::-webkit-scrollbar-track {
  background-color: transparent; /* Set the color of the scrollbar track */
}

.modal-content {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.above-all-backdrop {
  z-index: 8000;
}

.modal:has(.above-all-dialog) {
  z-index: 8001;
}

.calendar-selected-record {
  border: 1px solid red;
}

.ball,
.menu-item {
  background: var(--bs-primary);
  border-radius: 100%;
  width: 70px;
  height: 70px;
  position: absolute;
  color: white;
  text-align: center;
  transform: translate3d(0, 0, 0);
  transition: transform ease-out 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
}

.menu-open {
  display: none;
}

.menu {
  position: fixed;
  z-index: 30;
  right: 16px;
  bottom: 16px;
  width: 70px;
  height: 70px;
  box-sizing: border-box;
  text-align: left;
}

.menu a:hover {
  color: white !important;
}

.menu-open-button {
  background: var(--bs-primary);
  border-radius: 100%;
  width: 70px;
  height: 70px;
  position: absolute;
  color: white;
  text-align: center;
  transform: translate3d(0, 0, 0);
  transition: transform ease-out 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  z-index: 2;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 400ms;
  cursor: pointer;
  transform: scale(1.02, 1.02) translate3d(0, 0, 0);
}

.menu-open:checked + .menu-open-button {
  transition-timing-function: linear;
  transition-duration: 200ms;
}

.menu-open:checked ~ .menu-item {
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.menu-open:checked ~ .menu-item:nth-child(3) {
  transition-duration: 90ms;
  transform: translate3d(-80px, 0, 0);
}

.menu-open:checked ~ .menu-item:nth-child(4) {
  transition-duration: 190ms;
  transform: translate3d(-160px, 0, 0);
}

.menuAnimation {
  transition: 0.25s ease;
}

.fc {
  user-select: none;
}

.fc .fc-daygrid-day-top {
  justify-content: center;
}

.fc .fc-daygrid-day-number {
  padding: 0px;
  color: var(--bs-body-color);
  text-decoration: none;
}

.fc-col-header-cell-cushion {
  color: var(--bs-body-color);
  text-decoration: none;
}

.has-event-marker {
  display: inline-block;
  width: 5px; /* Adjust the marker size */
  height: 5px; /* Adjust the marker size */
  background-color: var(--bs-body-color); /* Customize the marker color */
  border-radius: 50%; /* Make it a circle */
  vertical-align: middle; /* Align it vertically with the day number */
}

.has-not-event-marker {
  display: inline-block;
  width: 5px; /* Adjust the marker size */
  height: 5px; /* Adjust the marker size */
  background-color: transparent; /* Customize the marker color */
  border-radius: 50%; /* Make it a circle */
  vertical-align: middle; /* Align it vertically with the day number */
}

.calendar-record-color {
  display: inline-block;
  width: 12px; /* Adjust the marker size */
  height: 12px; /* Adjust the marker size */
  border-radius: 50%;
  margin-right: 4px; /* Make it a circle */
  vertical-align: middle; /* Align it vertically with the day number */
}

/* Hide events in dayGridWeek view */
.fc-dayGridWeek-view .fc-event {
  display: none;
}

/* Hide events in dayGridMonth view */
.fc-dayGridMonth-view .fc-event {
  display: none;
}

.s1detailgrid-bar,
.s1reldocs-bar {
  border-radius: var(--bs-border-radius) var(--bs-border-radius) 0 0;
  background-color: var(--bs-primary);
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity));
  display: flex;
  justify-content: flex-end;
  width: 100%;
  min-height: 2.5rem;
  padding: 0.25rem;
}

.s1-panel-title {
  font-size: 1.2rem;
  border-radius: var(--bs-border-radius);
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity));
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
  padding: 0.5rem 0;
  margin: 0.5rem 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.form-tab {
  user-select: none;
  cursor: pointer;
  display: flex;
  border-bottom: none;
  margin-bottom: 0px;
  font-size: 1.2rem;
  font-weight: 400;
  align-items: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0.25rem;
}
.form-tab.active {
  border-bottom: 2px solid var(--bs-primary);
  margin-bottom: -2px;
  font-size: 1.3rem;
  font-weight: 500;
}

.s1-pull-to-refresh {
  width: 100%;
  height: 100%;
}

.refresh-view {
  width: 100%;
  height: 100%;
}

span.bi.bi-left-arrow-fc::before {
  content: "<";
}

span.bi.bi-right-arrow-fc::before {
  content: ">";
}

.grid-lines,
.rel-doc-lines {
  min-height: 2.5rem;
  overflow: hidden auto;
  border: 1.5px var(--bs-border-style) var(--bs-border-color);
}

.grid-line,
.rel-doc-line {
  padding: 0.5rem 0.25rem;
  display: flex;
  border-bottom: 1px solid var(--bs-border-color);
}

.grid-line.row-selected,
.rel-doc-line.row-selected {
  background-color: var(--bs-row-selected);
  border-bottom: 1px solid var(--bs-primary);
}

.grid-line-col,
.rel-doc-line-col {
  display: inline-block;
  max-width: 100%;
  margin-right: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.grid-line-col > span,
.rel-doc-line-col > span {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.grid-line-col > span:first-child,
.rel-doc-line-col > span:first-child {
  color: rgba(var(--bs-body-color-rgb), 0.65);
}

.grid-line-col > span:nth-child(2),
.rel-doc-line-col > span:nth-child(2) {
  font-weight: 600;
}

.s1-showroom-card {
  background-color: var(--card-bg);
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  flex-basis: 200px;
  /* min-height: 350px; */
  border-bottom: 2px solid var(--bs-primary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  min-height: 370px;
  max-height: 370px;

  @media only screen and (max-width: 432px) {
    flex-direction: row !important;
    padding: 0.5rem;
    height: 166px;
    min-height: 166px;
  }
}

.s1-showroom-card-info {
  padding: 0.5rem;
  max-height: 170px;
  min-height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  @media only screen and (max-width: 432px) {
    padding: 0 0 0 0.5rem;
    max-height: 150px;
    min-height: 150px;
  }
}

.s1-showroom-card-info-header {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-height: 66px;
  line-height: 22px;
  font-size: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.s1-showroom-card-info-detail {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.s1-showroom-card-image {
  height: 200px;
  min-height: 200px;
  border-bottom: 2px solid var(--bs-border-color);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media only screen and (max-width: 432px) {
    height: 150px;
    min-height: 150px;
    width: 105px;
    min-width: 105px;
    border-bottom: none;
  }
}

.s1-showroom-card .s1-showroom-card-image.no-image {
}

.s1-showroom-card-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.s1-cart-body .s1-cart-list-row:first-child {
  border-top: 1px solid var(--bs-gray-700);
}

.s1-cart-list-row {
  padding: 0.25rem 0;
  border-bottom: 1px solid var(--bs-gray-700);
  overflow-x: hidden;
  display: flex;
  align-items: center;
  width: 100%;
}

.s1-cart-list-row .image-div {
  border: 1px dotted rgb(65, 70, 75);
  margin-right: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  flex: 15%;
}

.s1-cart-list-row-info {
  flex: 70%;
  display: flex;
  flex-direction: column;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis;
}

.s1-cart-list-row .s1-cart-list-row-detail {
  overflow: hidden;
  text-overflow: ellipsis;
}

.s1-cart-list-row-detail span {
  font-weight: 500;
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap; */
}

.s1-cart-list-row-buttons {
  flex: 15%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow-x: hidden;
  gap: 1rem;
}
